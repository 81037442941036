const EditForm = ({
  todos,
  setTodos,
  setShowEditForm,
  editTaskArea,
  setEditTaskArea,
  editTodo,
}) => {
  const inputHandler = (e) => {
    setEditTaskArea(e.target.value);
  };

  const submitEditHandler = (e) => {
    e.preventDefault();

    const newTodos = todos.map((todo) =>
      todo.id === editTodo.id
        ? {
            id: editTodo.id,
            content: editTaskArea,
            completed: editTodo.completed,
          }
        : todo
    );

    if (editTaskArea !== "") {
      setTodos(newTodos);
      setShowEditForm(false);
    } else {
      setShowEditForm(false);
    }
  };

  return (
    <form onSubmit={submitEditHandler}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          onChange={inputHandler}
          value={editTaskArea}
          autoFocus
        />
        <button type="submit" className="btn btn-outline-primary">
          Update
        </button>
      </div>
    </form>
  );
};

export default EditForm;
