// Icons
import { AiOutlinePlus } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import noelHat from "../images/SantaClaus-hat.png";

const Header = ({
  status,
  setStatus,
  showAddForm,
  setShowAddForm,
  setShowEditForm,
  showSearchInput,
  setShowSearchInput,
  setSearch,
}) => {
  // Show or hide completed tasks
  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  return (
    <header>
      <div className="title text-primary">
        <h1>Remind Me</h1>
        <img src={noelHat} alt="Noel Hat" title="Happy New Year !" />
      </div>
      <div className="actions d-flex">
        <button
          className="btn search-trigger"
          onClick={() => {
            setShowSearchInput(!showSearchInput);
            setSearch("");
          }}
        >
          <span className="reactIcon" title="Find a task">
            <BsSearch />
          </span>
        </button>
        <select
          className="form-select form-select-sm unselectable filteredTasks-trigger"
          onChange={statusHandler}
          defaultValue={status}
        >
          <option value="all">All</option>
          <option value="completed">Completed</option>
          <option value="uncompleted">Uncompleted</option>
        </select>
        <button
          className="btn text-primary createTask-trigger"
          title="Add a new task"
          onClick={() => {
            setShowEditForm(false);
            setShowAddForm(!showAddForm);
          }}
        >
          <span className="reactIcon">
            <AiOutlinePlus />
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
