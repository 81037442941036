// Icons
import { BiCopyright } from "react-icons/bi";

const Footer = () => {
  return (
    <footer>
      <p>
        <span className="reactIcon">
          <BiCopyright />
        </span>
        <span id="copyright-year">2022</span> All Rights Reserved. Powered by{" "}
        <a
          href="https://ahmedfarhat.com"
          target="_blank"
          rel="noreferrer noopener"
          title="Ahmed Farhat"
          className="text-primary"
        >
          ROOT
        </a>
      </p>
    </footer>
  );
};

export default Footer;
