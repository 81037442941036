import Tasks from "./components/Tasks";
// import Categories from "./components/Categories";

function App() {
  return (
    <div className="app">
      {/* Still trying to find a solution for categories !!! */}
      {/* <div className="sidepanel">
        <Categories />
      </div> */}
      <div className="content">
        <Tasks />
      </div>
    </div>
  );
}

export default App;
