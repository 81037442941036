import { v4 as uuidv4 } from "uuid";

const AddForm = ({
  addTaskArea,
  setAddTaskArea,
  todos,
  setTodos,
  setShowAddForm,
}) => {
  const inputHandler = (e) => {
    setAddTaskArea(e.target.value);
  };

  const submitAddHandler = (e) => {
    e.preventDefault();
    if (addTaskArea !== "") {
      setTodos([
        ...todos,
        { id: uuidv4(), content: addTaskArea, completed: false },
      ]);
      setAddTaskArea("");
      setShowAddForm(false);
    } else {
      setShowAddForm(false);
    }
  };

  return (
    <form onSubmit={submitAddHandler}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          onChange={inputHandler}
          value={addTaskArea}
          autoFocus
        />
        <button type="submit" className="btn btn-outline-primary">
          Add
        </button>
      </div>
    </form>
  );
};

export default AddForm;
