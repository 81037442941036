import { useEffect, useState } from "react";
import Snowfall from "react-snowfall";
import Header from "./Header";
import Footer from "./Footer";
import AddTaskForm from "./AddTaskForm";
import EditTaskForm from "./EditTaskForm";
// Icons
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";

const Tasks = () => {
  const [addTaskArea, setAddTaskArea] = useState("");
  const [editTaskArea, setEditTaskArea] = useState("");
  const [todos, setTodos] = useState([]);
  const [filteredTodos, setFilteredTodos] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editTodo, setEditTodo] = useState(null);
  const [status, setStatus] = useState("all");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const loadLocalTodos = () => {
      if (localStorage.getItem("tasks") === null) {
        localStorage.setItem("tasks", JSON.stringify([]));
      } else {
        let backup = JSON.parse(localStorage.getItem("tasks"));
        setTodos(backup);
      }
    };

    loadLocalTodos();
  }, []);

  useEffect(() => {
    const filterHandler = () => {
      switch (status) {
        case "completed":
          setFilteredTodos(todos.filter((todo) => todo.completed === true));
          break;
        case "uncompleted":
          setFilteredTodos(todos.filter((todo) => todo.completed === false));
          break;
        default:
          setFilteredTodos(todos);
          break;
      }
    };

    const saveLocalTodos = () => {
      localStorage.setItem("tasks", JSON.stringify(todos));
    };

    filterHandler();
    saveLocalTodos();
  }, [todos, status]);

  const TodoItem = (props) => {
    // Set task as completed
    const completeHandler = () => {
      setTodos(
        todos.map((item) => {
          if (item.id === props.todo.id) {
            return {
              ...item,
              completed: !item.completed,
            };
          }
          return item;
        })
      );
    };

    // Edit task
    const editHandler = () => {
      setShowAddForm(false);
      if (!showEditForm) {
        setShowEditForm(true);
      }
      if (showEditForm && editTaskArea === props.todo.content) {
        setShowEditForm(false);
      }
      setEditTaskArea(props.todo.content);
      const selectedTodo = todos.find((todo) => todo.id === props.todo.id);
      setEditTodo(selectedTodo);
    };

    // Remove task
    const removeHandler = () => {
      setShowEditForm(false);
      setTodos(todos.filter((item) => item.id !== props.todo.id));
    };

    return (
      <tr className={`tasksItem${props.todo.completed ? " completed" : ""}`}>
        <td className="completed-container">
          <button
            className={`btn${
              props.todo.completed ? " btn-success" : " btn-outline-success"
            } completeTask-trigger`}
            title={`Mark as ${
              props.todo.completed ? "uncompleted" : "completed"
            }`}
            onClick={completeHandler}
          >
            <span className="reactIcon">
              <BsCheck />
            </span>
          </button>
        </td>
        <td className="text-container">{props.todo.content}</td>
        <td className="editRemove-container">
          <button
            className="btn btn-warning editTask-trigger"
            title="Edit"
            onClick={editHandler}
          >
            <span className="reactIcon">
              <FaPencilAlt />
            </span>
          </button>
          <button
            className="btn btn-danger removeTask-trigger"
            title="Delete"
            onClick={removeHandler}
          >
            <span className="reactIcon">
              <FaTrashAlt />
            </span>
          </button>
        </td>
      </tr>
    );
  };

  return (
    <main className="tasks">
      <Snowfall snowflakeCount={150} />
      <Header
        status={status}
        setStatus={setStatus}
        showAddForm={showAddForm}
        setShowAddForm={setShowAddForm}
        setShowEditForm={setShowEditForm}
        showSearchInput={showSearchInput}
        setShowSearchInput={setShowSearchInput}
        setSearch={setSearch}
      />
      <div className="table-container">
        {showSearchInput && (
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            className="form-control searchBar"
            autoFocus
          />
        )}
        <table className="table table-hover">
          <tbody>
            {filteredTodos
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.content.toLowerCase().includes(search.toLowerCase());
              })
              .map((todo) => (
                <TodoItem key={todo.id} todo={todo} />
              ))}
          </tbody>
        </table>
        {(showAddForm || showEditForm) && (
          <div className="form-container">
            {showAddForm && (
              <AddTaskForm
                addTaskArea={addTaskArea}
                setAddTaskArea={setAddTaskArea}
                todos={todos}
                setTodos={setTodos}
                setShowAddForm={setShowAddForm}
              />
            )}
            {showEditForm && (
              <EditTaskForm
                todos={todos}
                setTodos={setTodos}
                setShowEditForm={setShowEditForm}
                editTaskArea={editTaskArea}
                setEditTaskArea={setEditTaskArea}
                editTodo={editTodo}
              />
            )}
          </div>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Tasks;
